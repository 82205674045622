import { IJsonSchemaObject, IUiSchema } from "./UiJsonSchemaTypes";


export interface ISchemaOptions {
	treatNullAsUndefined: boolean;
	useDefaults: boolean;
	contOnError: boolean;
	skipOnNullType: boolean;
}


export interface ISchemaLib {
	[name: string]: (...any: any[]) => any;
}

export interface IInnerStates {
	[path: string]: {
		error: string;
		modified: boolean;
	};
}


export function evalSchemaElem(schema: IJsonSchemaObject, baseSchema: IJsonSchemaObject | undefined, elemProxy: any,
							   innerStates: IInnerStates, options: ISchemaOptions, lib: ISchemaLib, objects: IExprObjects, required = false): any {

	// The $ref object if present is evaluated ignoring all sibling properties.
	if (schema?.$ref) {
		if (schema.$ref.startsWith("#/")) {
			const pathStr = schema.$ref.substring(2);
			const pathArr = pathStr ? pathStr.split("/") : [];
			const rootElemProxy = elemProxy["/?"];
			let   rootSchema = rootElemProxy.__getSchema__;

			for (const pe of pathArr) {
				rootSchema = rootSchema[pe];
				if (!rootSchema) { 
					throw Error("Invalid path in $ref " + schema.$ref);
				}
			}
			return evalSchemaElem(rootSchema, rootSchema, elemProxy, innerStates, options, lib, objects);

		} else {
			throw Error("unsupported $ref " + schema.$ref);
		}
	}

	// Resolve the target object
	const elemP = elemProxy["."];
	const elemV = (elemP === undefined || (options.treatNullAsUndefined && elemP === null)) ? undefined : elemP;
	const elem  = (elemV === undefined && options.useDefaults && !innerStates[elemProxy.__getPath__]?.modified)
						? (schema?.default !== undefined ? schema.default :
						   baseSchema?.default !== undefined ? baseSchema.default : undefined)
						: elemV;


	// do the custom evaluation if installed
	if (schema?.$uiSchemaObject?.evalError) {
		const objs = {...objects, values: elemProxy["..?"] };
		const err = evalExpr(schema.$uiSchemaObject?.evalError, lib, objs, 
							 { fullkey: elemProxy.__getPath__, value: elem, readOnly: null, schema });
		if (err) { return { err }; }
	}

	// if elem is undefined, that means there is no value, we simply return
	if (elem === undefined) { return required ? { err: "required field" } : null; }

	// if schema is null we can return here
	if (schema == null) { return null; }

	// Determine the type
	const mainTypeofElem = elem === null ? "null" : Array.isArray(elem) ? "array" : typeof elem;
	const typeofElem = mainTypeofElem === "number" && Math.floor(elem) === elem ? "integer" : mainTypeofElem;
	const types: string[] | null = schema.type ? (Array.isArray(schema.type) ? schema.type : [schema.type]) : null;

	// skip verification only if type is explictly set to null (so we must check ===)
	if (schema.type === null && options.skipOnNullType) { return null; }

	if (types && !types.includes(typeofElem) && !(typeofElem === "integer" && types.includes("number"))) {
		// console.log("evalSchemaElem", elem, elemP, elemProxy.__getPath__, defaultValue);
		// console.log("wrong type, expected " + schema.type + " got " + typeofElem);
		return { err: "wrong type, expected " + types.toString() + " got " + typeofElem }
	}


	// Check for any of the combi rules
	if (schema.not) {
		if (!evalSchemaElem(schema.not, baseSchema, elemProxy, innerStates, options, lib, objects)) {
			return { err: "not condition not matched" };
		}
	}
	if (schema.allOf) {
		for (const aoSchema of schema.allOf) {
			const res: any = evalSchemaElem(aoSchema, baseSchema, elemProxy, innerStates, options, lib, objects);
			if (res) { return res; }
		}
	}
	if (schema.anyOf) {
		let matched = false;
		for (const aoSchema of schema.anyOf) {
			if (!evalSchemaElem(aoSchema, baseSchema, elemProxy, innerStates, options, lib, objects)) {
				matched = true;
				break;
			}
		}
		if (!matched) { return { err: "anyOf condition not matched" }; }		// TODO: improve
	}
	if (schema.oneOf) {
		let matched = false;
		let lastError: any;
		for (const ooSchema of schema.oneOf) {
			lastError = evalSchemaElem(ooSchema, baseSchema, elemProxy, innerStates, options, lib, objects);
			if (!lastError) {
				if (matched) { matched = false;	break; }
				matched = true;
			}
		}
		// in case of no match, we return the error message of the last checked condition
		if (!matched) { return lastError || { err: "oneOf condition not matched" } };
	}


	// use val() function to get any value of the conditions, as this will implement the optional
	// $data reference. E.g. maximum: { $data: "1/lower_rage" }
	const val = (v: any) => (v && typeof v === "object" && v["$data"]) ? elemProxy[v["$data"]] : v;

	const enuum = val(schema.enum);
	if (enuum && !enuum.includes(elem)) {
		return { err: "value not in enum" };
	}
	const coonst = val(schema.const);
	if (coonst !== undefined && elem !== coonst) {
		return { err: "value doesn't match expected const" };
	}

	if ((typeofElem === "number" || typeofElem === "integer") &&
	    (schema.multipleOf || schema.maximum != null || schema.minimum != null
		  || schema.exclusiveMaximum != null || schema.exclusiveMinimum != null)) {

		const multipleOf = val(schema.multipleOf);
		if (multipleOf && elem / multipleOf !== Math.floor(elem / multipleOf)) {
			return { err: "value is not a multiple of " + multipleOf };
		}
		const maximum = val(schema.maximum);
		if (maximum != null && elem > maximum) {
			return { err: "value larger than max value " + maximum };
		}
		const minimum = val(schema.minimum);
		if (minimum != null && elem < minimum) {
			return { err: "value less than min value " + minimum };
		}
		const exclusiveMaximum = val(schema.exclusiveMaximum);
		if (exclusiveMaximum != null && elem >= exclusiveMaximum) {
			return { err: "value larger than exclusive max value " + exclusiveMaximum };
		}
		const exclusiveMinimum = val(schema.exclusiveMinimum);
		if (exclusiveMinimum != null && elem >= exclusiveMinimum) {
			return { err: "value less than exclusive min value " + exclusiveMinimum };
		}
	}

	if (typeofElem === "string" && (schema.minLength != null || schema.maxLength != null || schema.pattern != null)) {
		const minLength = val(schema.minLength);
		if (minLength != null && elem.length < minLength) {
			return { err: "length less that minimum length of " + minLength };
		}
		const maxLength = val(schema.maxLength);
		if (maxLength != null && elem.length > maxLength) {
			return { err: "length longer that maximum length of " + maxLength };
		}
		const pattern = val(schema.pattern);
		if (pattern != null && elem.match(pattern) == null) {
			return { err: schema?.$uiSchemaObject?.errorMsgPattern ?? "input doesn't match expected format" };
		}
	}

	// Handle ARRAY
	if (typeofElem === "array") {

		if (schema.minItems != null) {
			const minItems = val(schema.minItems);
			if (minItems != null && elem.length < minItems) {
				return { err: "number of items less that " + minItems };
			}
		}
		if (schema.maxItems != null) {
			const maxItems = val(schema.maxItems);
			if (maxItems != null && elem.length > maxItems) {
				return { err: "number of items more that " + maxItems };
			}
		}

		if (schema.items?.type && elemP) {

			let errArray;
			for (let idx = 0; idx < elem.length; idx++) {
				const arelem = elemProxy[idx + "?"];
				const dataSchema: IJsonSchemaObject     = (schema.itemsArray && schema.itemsArray[idx]) || schema.items;
				const dataBaseSchema: IJsonSchemaObject | undefined = (baseSchema?.itemsArray && baseSchema.itemsArray[idx]) || baseSchema?.items;

				const err: any = evalSchemaElem(dataSchema, dataBaseSchema, arelem, innerStates, options, lib, objects);

				if (err && Object.keys(err).length > 0) {
					if (!errArray) {
						errArray = [];
					}
					errArray[idx] = err;
				}
			}
			if (errArray) { return errArray; }
		}
	}


	// Handle OBJECT
	if (typeofElem === "object") {

		const errObj: any = {};
		const stopOnError = options.contOnError !== true;

		if (schema.properties || schema.required) {
			const requiredPropsMap: any = {};
			for (const prop of schema.required || []) {
				requiredPropsMap[prop] = true;
			}
			const allProps = {...schema.properties, ...requiredPropsMap};


			for (const key of Object.keys(allProps)) {

				const dataSchema     = (schema.properties && schema.properties[key]) || {};
				const required       = requiredPropsMap[key] || false;
				const dataBaseSchema = baseSchema?.properties && baseSchema?.properties[key];

				let dataElem   = elemProxy;
				for (const pkey of key.split(".")) {
					dataElem = dataElem && dataElem[pkey + "?"]
				}

				const fullpath = dataElem && dataElem.__getPath__;
				if (innerStates[fullpath]?.error) { 
					errObj[key] = { err: innerStates[fullpath].error };
					continue;
				}

				// Evaluate the basic schema features
				const err = evalSchemaElem(dataSchema, dataBaseSchema, dataElem, innerStates, options, lib, objects, required);
				if (err) { 
					errObj[key] = err;
					if (stopOnError) { return errObj; }
				}
			}
		}

		// deepProperties and deepRequired is used to evaluate a value anywhere in the global object
		if (schema.deepProperties || schema.deepRequired) {
			const errObj: any = {};

			const requiredPropsMap: any = {};
			for (const prop of schema.deepRequired || []) {
				requiredPropsMap[prop] = true;
			}
			const allProps = {...schema.deepProperties, ...requiredPropsMap};
	

			for (const key of Object.keys(allProps)) {
				if (errObj[key]) { continue; }

				const dataSchema = (schema.deepProperties && schema.deepProperties[key]) || {};
				const required   = requiredPropsMap[key];
				const akey       = key.replace(/[.]/g, "/");
				const dataElem   = elemProxy && elemProxy[akey + "?"]

				// Evaluate the basic schema features
				const err = evalSchemaElem(dataSchema, dataSchema, dataElem, innerStates, options, lib, objects, required);
				if (err) { 
					errObj[key] = err;
					if (stopOnError) { return errObj; }
				}
			}
		}

		if (Object.keys(errObj).length > 0) {
			return errObj;
		}
	}

	return null;
}







function deepMerge(src: any, dst: any, __cnd?: any) {

	for (const key of Object.keys(src)) {
		if (typeof src[key] === "object" && dst[key] && typeof dst[key] === "object" && !Array.isArray(dst[key])) {
			dst[key] = deepMerge(src[key], {...dst[key]});
			if (__cnd) {
				dst[key].__cnd = dst[key].__cnd || [];
				dst[key].__cnd.push(__cnd);
			};
		} else {
			dst[key] = __cnd ? { ...src[key], __cnd: [__cnd] } : src[key];
		}
	}

	return dst;
}




export function updateConditionalSchema(schema: IJsonSchemaObject, object: any, schemaOptions: ISchemaOptions,
										lib: ISchemaLib, objects: IExprObjects, root?: string) {

	function getFullPath(path: string) {
		if (path[0] >= "0" && path[0] <= "9" && path[1] === "/") {
			let cnt = parseInt(path[0]);
			let prefix = "";
			while (cnt--) { prefix += "../"; }
			return (root ? root + "/" : "") + prefix + path.substring(2);
		} else {
			return (root ? root + "/" : "") + path;
		}
	}

//	const treatNullAsUndefined = schemaOptions && schemaOptions.treatNullAsUndefined;
	const ms = {...schema};
	ms.properties = {...ms.properties};

	const apply = (scope: IJsonSchemaObject, __cnd: any) => {
		if (scope.properties && ms.properties) {
			deepMerge(scope.properties, ms.properties, __cnd);
		}
		if (scope.required && scope.required.length > 0) {
			for (const key of scope.required) {
				if ((ms.required || []).includes(key) === false) {
					ms.required = [...(ms.required || []), key]
				}
			}
		}
	};

	const ifThenElse = (scope: IJsonSchemaObject) => {

		if (scope.if) {

			let   cond = true;
			const err  = evalSchemaElem(scope.if, ms, root ? object[root + "?"] : object, {}, schemaOptions, lib, objects);
			if (err && Object.keys(err).length > 0) { cond = false; }

			if (cond  && scope.then) { apply(scope.then, { if: scope.if, then: scope.then }); }
			if (!cond && scope.else) { apply(scope.else, { if: scope.if, else: scope.else }); }

		}

		if (scope.allOf) {
			for (const elem of scope.allOf) {
				ifThenElse(elem);
			}
		}
	}

	ifThenElse(ms);

	for (const key of Object.keys(ms.properties || {})) {
		const prop = ms.properties[key];
		if (prop.type === "object") {

			if (!prop.properties) {
				// console.log("Error in schema, expected to find properties field on object", key, prop);
				continue;
			}
			ms.properties[key] = updateConditionalSchema(prop, object, schemaOptions, lib, objects, getFullPath(key));
		}
		if (prop.type === "array") {
			if (!prop.items) {
				console.log("Error in schema, expected to find items field on array");
				continue;
			}
			const arr = object[getFullPath(key)];
			if (arr) {
				if (!Array.isArray(arr)) {
					console.log("error in data, expected array");
					continue;
				}
				ms.properties[key] = {...ms.properties[key], itemsArray: []};
				for (let idx = 0; idx < arr.length; idx++) {
					(ms.properties[key].itemsArray as IJsonSchemaObject[])[idx] = updateConditionalSchema(prop.items, object, schemaOptions, lib, objects, getFullPath(key)+ "/" + idx);
				}
			}
		}
	}

	return ms;
}







export function getObjectValues(condSchema: IJsonSchemaObject, dataObject: any, schemaOptions: ISchemaOptions, lib: ISchemaLib, objects: IExprObjects) {

	const treatNullAsUndefined = schemaOptions && schemaOptions.treatNullAsUndefined;
	const useDefaults = schemaOptions ? schemaOptions.useDefaults : true;
	const readOnly = false;		// FIXME: 

	function parseProperties(updateSchema: IJsonSchemaObject, dataObj: any) {
		const resObject: any = {};

		if (updateSchema.properties) {
			for (const key of Object.keys(updateSchema.properties)) {
				const schemaElem = updateSchema.properties[key];
				const uiSchemaElem = schemaElem.$uiSchemaObject || {};

				let virtual = uiSchemaElem.virtual === true;
				const value = dataObj[key];

				if (typeof uiSchemaElem.virtual === "string") {

					// FIXME: key shoud be fullkey
					const objs = { ...objects, values: dataObj };
					virtual = evalExpr(uiSchemaElem.virtual, lib, objs, { fullkey: key, value, readOnly, schema: schemaElem });
				}

				// Always skip (i.e. don't include in object) virtual fields, readOnly fields and fields without type.
				if (schemaElem.readOnly || schemaElem.type == null || virtual) { continue; }

				if (value === undefined || (treatNullAsUndefined && value === null)) {
					if (useDefaults && schemaElem.default !== undefined) {
						resObject[key] = schemaElem.default;
					}

				} else {

					if (schemaElem.type === "object") {

						resObject[key] = schemaElem.properties ? parseProperties(schemaElem, value || {}) : value;

					} else if (schemaElem.type === "array") {

						resObject[key] = [];
						for (let idx = 0; Array.isArray(value) && idx < value.length; idx++) {

							const arrValue = value[idx];
							const arrSchemaElem = (schemaElem?.itemsArray && schemaElem?.itemsArray[idx]) || schemaElem?.items;

							if (arrValue === undefined || (treatNullAsUndefined && arrValue === null)) {
								if (useDefaults && arrSchemaElem?.default !== undefined) {
									resObject[key][idx] = arrSchemaElem.default;
								}
			
							} else {
			
								if (arrSchemaElem?.type === "object") {
									resObject[key][idx] = parseProperties(arrSchemaElem, arrValue || {});
								} else {
									resObject[key][idx] = arrValue;
								}

							}

						}

					} else {
						resObject[key] = value;
					}

				}
			}
		}
		return resObject;
	}

	return parseProperties(condSchema, dataObject);
}


export function getPathAndKey(fullkey: string) {

	const keypathArr = (fullkey || "").split("/");
	const key = keypathArr.pop();
	const keypath = keypathArr.join("/");

	return { key, keypath };
}


// An expression will have the following variables
//  value - the current value of this element
//  error - error object for current element
//  schema - object with the current schema
//  objects.values - object with all the values
//  objects.errors - object with all the errors
//

export interface IExprObjects {
	jsonSchema: IJsonSchemaObject;
	uiSchema: IUiSchema;
	values: any;
	errors: any;
	oldValues: any;
	newValues?: any;
	diffValues?: any;
}

interface IExprScope {
	fullkey?: string;
	value?: any;
	error?: any;
	schema?: IJsonSchemaObject;
	readOnly: boolean;

	status?: number;
	name?: string;			// e.g. filename on OnDrop
}


let logDict = false;

export function evalExpr(text: string, lib: ISchemaLib, objects: IExprObjects, scope: IExprScope) {

	// @ts-ignore
	const { fullkey, value, error, schema, status, name, readOnly } = scope;

	const $dict = objects?.uiSchema?.dict || {};
	let keypath: string[] | string = (fullkey || "").split("/");
	const key = keypath.pop();
	keypath = keypath.join("/");

	try {
		// eslint-disable-next-line no-eval
		const res = eval(text);
		return res;

	} catch (e: any) {
		// we need to make this trick to have a false condition on $dict here so we can reference
		// dict. Otherwise the compiler will complain, and we need it in scope for the eval.
		console.log("Error in eval expression: ", text, e.message, key, logDict && $dict);
		throw e;
	}
}


// This version of evalExpr maintains a cache of compiled functions for faster processing.
// Actual time saving is still to be evaluated.
//
// FIXME: this optimization doesn't work for e.g. create organization so it is disabled at the moment.

const evalExprCache: {
	[expr: string]: (lib: ISchemaLib, objects: IExprObjects, scope: IExprScope, $dict: any, keypath: string, key: string) => any;
} = {};

export function evalExpr2(text: string, lib: ISchemaLib, objects: IExprObjects, scope: IExprScope) {

	const $dict = objects?.uiSchema?.dict || {};
	let keypath: string[] | string = (scope.fullkey || "").split("/");
	const key = keypath.pop()!;
	keypath = keypath.join("/");

	try {

		let fn = evalExprCache[text];
		if (!fn) {
			fn = eval(`((lib, objects, scope, $dict, keypath, key) => {
				const { fullkey, value, error, schema, status } = scope;
				return ${text};
			})`);
			evalExprCache[text] = fn;
		}

		const res = fn(lib, objects, scope, $dict, keypath, key);
		return res;

	} catch (e: any) {
		console.log("Error in eval expression: ", text, e.message, scope.fullkey);
		throw e;
	}
}






export function evalString(txt: string | null | undefined, lib: ISchemaLib, objects: IExprObjects, scope: IExprScope) {

	if (txt == null || txt === "") { return ""; }
	let text = txt + "";	// convert to string

	function findNext(pos: number) {
		const openIdx = text.indexOf("{{", pos);
		const closeIdx = text.indexOf("}}", pos);

		if (openIdx >= 0 && (closeIdx < 0 || closeIdx > openIdx)) { return { pos: openIdx, tok: "{{" }; }
		if (closeIdx >= 0) { return { pos: closeIdx, tok: "}}" }; }
		return null;
	}


	let res: string|object = "";
	for (;;) {

		let nest = 1;
		let pos = 0;
		const starttok = findNext(pos);
		if (starttok == null) {
			res = text ? res.toString() + text : res;
			break;
		}

		if (starttok.tok === "}}") { throw new Error("unexpected closing }}")}
		pos = starttok.pos + 2;
		let nexttok: { pos: number, tok: string } | null;
		do {
			nexttok = findNext(pos);;
			if (nexttok == null) { throw new Error("no closing }}")}

			if (nexttok.tok === "}}") { nest--; }
			if (nexttok.tok === "{{") { nest++; }
			pos = nexttok.pos + 2;

		} while (nest > 0);

		res += text.substring(0, starttok.pos);
		const expr = evalExpr(text.substring(starttok.pos + 2, nexttok.pos), lib, objects, scope);
		res = res ? res.toString() + expr : expr;

		text = text.substring(nexttok.pos + 2);
	}

	return res;
}




export function verificationDescription(schema: IJsonSchemaObject) {

	let str = "";
	const spc = () => str ? ", " : "";
	if (schema.readOnly) {
		str += spc() + "readonly";
	}
	if (schema.type) {
		str += spc() + schema.type.toString();
	}
	if (schema.minimum != null && schema.maximum != null) {
		str += spc() + schema.minimum + " < value < " + schema.maximum;
	} else if (schema.minimum != null) {
		str += spc() + "value > " + schema.minimum;
	} else if (schema.maximum != null) {
		str += spc() + "value < " + schema.maximum;
	}

	if (schema.minLength != null && schema.maxLength != null) {
		str += spc() + schema.minLength + " < length < " + schema.maxLength;
	} else if (schema.minLength != null) {
		str += spc() + "length > " + schema.minLength;
	} else if (schema.maxLength != null) {
		str += spc() + "length < " + schema.maxLength;
	}
	if (schema.enum && Array.isArray(schema.enum) && schema.enum.length) {
		str += spc() + " values: " + schema.enum.map(v => v == null ? "null" : v).join(", ")
	}
	if (schema.pattern) {
		str += spc() + " pattern";
	}

	return str;
}




//
// proxyClone(root: any, subproxy?: boolean, objpath?: string, obj?: any, noProxy?: boolean)
//   root - is the root object. Can always reach via /
//   objpath - the current path relative to root of the proxy
//

// proxyClone(obj) create an proxied object that is read-only and more importantly can be accessed with a
// path. That means that if you have the following object:
//
//   obj = {
//		a: {
//			aa: 10,
//   	},
//      b: {
//         bb: 20,
//      }
//   }
//
// the proxied object can be accessed these ways:
//   obj.a.b     - normal way
//   obj["a/b"]  - path way
//
// moreover the object can be sub-referenced while maintaining access to the root. For example:
//
//   subobj = obj.a;
//
// Now the subobj will hold the object { aa: 10 }. Suppose one would like to access the neighbor object
// b and bb. This can be done this way:
//
//  bobj = subobj["/b"]   returns object b { bb: 20 }
//  bb = subovj["/b/bb"]    returns 20
//  bb = subovj["../bb"]    returns 20
//  bb = subovj["/b"].bb    returns 20
//
// subobj["/b?"] returns a new proxy




function getSchema(root: any, rootSchema: IJsonSchemaObject, path: string) {

	const pathelems = path ? path.split("/") : [];

	let schema: IJsonSchemaObject | undefined = rootSchema;
	for (const pelem of pathelems) {
		if (schema.type === "object") {
			schema = schema?.properties && schema?.properties[pelem];
		} else if (schema.type === "array") {
			schema = (schema.itemsArray && schema.itemsArray[Number(pelem)]) || schema.items;
		}
		if (!schema) { return null; }
	}

	return schema;
}

export function proxyClone(root: any, rootSchema: IJsonSchemaObject, objpatharg?: string) {

	let objpath = "";

	const handle: ProxyHandler<any>  = {
		get: (target: any, prop: string, receive: any) => {

			// console.log("get ", target, prop);

			// when we arrive here we know the root and the objpath
			if (typeof prop === "string") {

				if (prop === "__getPath__") {
					return objpath || "";
				}
				if (prop === "__getSchema__") {
					return getSchema(root, rootSchema, objpath);
				}
				if (prop.startsWith(".") || prop.indexOf("/") >= 0 || prop.endsWith("?")) {
					if (prop === ".") {
						return target.hasOwnProperty("__selfprop") ? target.__selfprop : target;
					} else if (prop.startsWith("/")) {
						prop = prop.substring(1);
					} else if (prop[0] >= "0" && prop[0] <= "9" && prop[1] === "/") {
						let cnt = parseInt(prop[0]);
						let prefix = "";
						while (cnt--) { prefix += "../"; }
						prop = (objpath ? objpath + "/" : "") + prefix + prop.substring(2);
					} else {
						prop = (objpath ? objpath + "/" : "") + prop;
					}

					return proxyClone(root, rootSchema, prop);
				}
			}

			return Reflect.get(target, prop, receive);
		}
	};


	if (!objpatharg) {
		// console.log("proxyClone root", root != null);

		if (root && typeof root === "object") {
			return new Proxy(root, handle);
		} else {
			return root;
		}
	}

	const proxy = objpatharg.endsWith("?");
	objpatharg = proxy ? objpatharg.substring(0, objpatharg.length - 1) : objpatharg;
	objpatharg = objpatharg.endsWith("/") ? objpatharg.substring(0, objpatharg.length - 1) : objpatharg;

	const patharr = objpatharg ? objpatharg.split("/") : [];

	// resolve negative path
	for (let idx = 0; idx < patharr.length; idx++) {
		if (patharr[idx][0] === ".") {
			if (patharr[idx] === "..") {
				if (idx < 1) {
					console.log("error reading " + objpatharg + " too many ..");
					return { obj: null };
				}
				patharr.splice(idx - 1, 2);
				idx -= 2;
			} else if (patharr[idx] === ".") {
				patharr.splice(idx, 1);
				idx--;
			}
		}
	}

	let obj = root;
	objpath = patharr.join("/");
//	const objpath = prop ? (jpath ? jpath + "/": "") + prop : jpath;

	for (const pe of patharr) {
		if (obj && typeof obj === "object") {
			obj = obj[pe];
		} else {
			obj = undefined;
			break;
		}
	}

	// console.log("proxyClone", objpatharg, objpath, root != null);

	if (proxy) {
		return new Proxy(typeof obj === "object" && obj ? obj : { __selfprop: obj }, handle)
	}

	return obj;

}
